.accordion {
  width: 100%;
  /* max-width: 600px; */
}

.accordion-item {
  border: none;
  border-bottom: 1px solid black;
}

.accordion-header {
  padding: 10px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.accordion-header img {
  width: 20px;
  height: 20px;
}

.accordion-content {
  display: none;
  padding: 10px;
}

.open .accordion-content {
  display: block;
}
