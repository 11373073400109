.background-container {
  background-color: #08a7a8;
}

.container {
  /* padding: 20px; */
}

.center {
  text-align: center;
}

.white-text {
  color: white;
}

.form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.form {
  width: 48%;
  max-width: 600px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

.input-container {
  position: relative;
}

.fa-icon {
  position: absolute;
  top: 35%;
  left: 90%;
  transform: translateY(-50%);
  color: #00a796;
}

.input-container input,
.input-container textarea {
  width: 100%;
  height: 50px;
  background-color: #ededed;
  border-radius: 5px;
  border: 1px solid #e4e7ee;
  margin: 6px 0px 25px !important;
  padding: 10px;
  box-sizing: border-box;
}

.image-container {
  /* width: 48%;
    margin: 20px; */
}

.image-container img {
  width: 100%;
  height: auto;
  /* border-radius: 10px; */
}

.flag {
  height: 30px;
  width: 50px;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.responsive-map {
  /* overflow: hidden;
  padding-bottom:10%;
  position: relative;
  height: 0; */
}
.responsive-map iframe {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 0;
}

/* Media queries for responsive design */

@media screen and (max-width: 768px) {
  .form-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .details {
    flex-direction: column !important;
  }

  .form,
  .image-container {
    width: 100%;
  }
}
