/* ProgressBar.css */
.progress-circle-container {
  position: fixed;
  bottom: 30px;
  right: 15px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .progress-circle-container {
    position: fixed;
    bottom: 15px;
    right: 20px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.progress-circle {
  width: 40px;
  height: 40px;
}

.progress-circle-bar {
  fill: none;
  stroke: #39c4a8;
  stroke-width: 5;
  stroke-dasharray: 283;
  stroke-dashoffset: 0;
  transform-origin: center;
  transition: stroke-dashoffset 0.3s ease;
}

.progress-background {
  fill: transparent;
  stroke: #74e6cf;
  stroke-width: 5;
  stroke-dasharray: none;
}

.scroll-to-top {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.scroll-to-top svg {
  display: block;
  width: 25px;
  height: 25px;
  stroke: #39c4a8;
  transition: all 0.1s ease;
}
