.img-card:hover {
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.09);
  border-color: #fff;
}

.Game-features {
  transition: transform 0.5s ease-in-out;
  /* margin-top: 200px; */
}

.col-md-4.hovered img {
  /* Styles to apply when the div is hovered */
  /* For example, you can change the border or add a box shadow */
  animation: bounce 1s 1; /* Add a bounce animation effect once */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-15px);
  }
}
