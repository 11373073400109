.technology-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s; /* Add a transition for a smooth effect */
}

.technology-card:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Add the shadow effect on hover */
}
.custom-button:hover {
  background-color: black !important;
  color: white !important;
  /* border: none !important; */
}
